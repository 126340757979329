.select-style-container {
    display: flex;
    flex-direction: column;
}

.style-item {
    width: 100%;
    margin: 0 5px;
    display: flex;
    align-items: center;
}

.map-style-modal > .modal {
    width: auto;
    min-width: 300px;
}

.style-name-container {
    margin-left: 10px;
    width: 100%;
}

.style-name-container > p {
    text-align: left;
    font-size: 15px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans;
    color: #050505;
}