.menu-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
}

.menu {
    padding: 15px;
    flex-grow: 1;
    overflow-y: auto;
}

.menu-content {
    display: flex;
    flex-direction: column;
    height: fit-content;
}


.back-menu {
    position: relative;
    display: flex;
    height: 30px;
    margin: 15px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
}

.back-menu-btn {
    position: absolute;
    left: 0;
}

.menu-title > h5 {
    font-size: 20px;
    font-family: 'monserrat';
}