.sidebar-card-container {
    position: relative;
    width: 100%;
    padding-top: 100%;
    background-color: whitesmoke;
    border-radius: 10px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.sidebar-card-container:hover {
    background-color: #e1dfdf;
}

.sidebar-card {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
}

.sidebar-card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.sidebar-card-icon > svg {
    font-size: 50px;
    color: #0067dd;
}

.sidebar-card-title{

}