.loading-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
}

.loading {
    display: inline-block;
    width: 70px;
    height: 70px;
    border: 4px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #0067dd;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
}
  
@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}