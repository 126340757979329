.maplibregl-style-list
{
  display: none;
}

.maplibregl-ctrl-group .maplibregl-style-list button
{
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 8px 8px 6px;
  text-align: right;
  width: 100%;
  height: auto;
}

.maplibregl-style-list button.active
{
  font-weight: bold;
}

.maplibregl-style-list button:hover
{
  background-color: rgba(0, 0, 0, 0.05);
}

.maplibregl-style-list button + button
{
  border-top: 1px solid #ddd;
}

.maplibregl-style-icon
{ 
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}
