.element-image {
    position: relative;
    width: 60px;
    height: 60px;
    
}

.element-image > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.elements-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -10px;
    margin-bottom: 10px;
}

.select-element {
    padding: 5px;
    margin: 10px;
    border: 2px solid;
    border-color: rgb(231, 229, 229);
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.selected-element {
    border-color: #0067dd;
    box-shadow: 0 0 13px -5px #0067dd;
}

.icons {
    height: 40px;
    width: 40px;
    background-size: 75%;
    cursor: pointer;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }