.element-name-container {
    width: 100%;
}

.element-name-container > p {
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
    color: #050505;
    text-align: left;
}

.element-properties-container {
    display: flex;
    flex-direction: column;
}

.element-propertie {
    text-align: left;
    color: #050505;
    font-size: 18px;
}