.button-base {
    width: 100%;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 8px;
    background-color: #0067dd;
    color: #FFF;
    font-size: 15px;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0,0,0,0.2);
    overflow: hidden !important;
	position: relative !important;
    transition: 0.3s ease-in-out;
}

.button-base:hover {
    background-color: #0177ff;
}

.button-base:disabled {
    background-color: #0067dd6e;
}

.button-base:disabled:hover {
    background-color: #0067dd6e;
}

.ripple {
	position: absolute;
	border-radius: 50%;
	transform: scale(0);
	animation: ripple 500ms ease-in-out;
	background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
	to {
		transform: scale(4);
		opacity: 0;
	}
}