.map-wrap {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  
  .map {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  