.users-item-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.users-item-container > p {
    margin-left: 5px;
    font-size: 15px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}