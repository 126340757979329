.error-page {
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    background-color: #fc6161;
}

.error-page-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.error-page-status-container, .error-page-message-container {
    display: flex;
    justify-content: center;
}

.error-page-status-container {
    height: 150px;
}

.error-page-status-container > p {
    font-size: 150px;
    letter-spacing: 5px;
    color: #FFF;
    font-family: 'monserrat';
}

.error-page-message-container > p {
    font-size: 20px;
    color: #FFF;
}