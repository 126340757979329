.panel-container {
    height: 100vh;
    width: 380px;
    position: absolute;
    padding: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    background-color: white;
    overflow: scroll;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.panel-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.panel-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}  

.property-name, .property-description {
    display: flex;
    margin-bottom: 10px;
}

.property-name h4 {
    font-size: 30px;
    color: #050505;
    margin: 0;
    text-align: center;
}

.property-description p {
    font-size: 15px;
    margin: 0;
    text-align: justify;
    word-break: break-all;
}

.property-image {
    position: relative;
    width: 100%;
    padding-top: 65%;
}

.property-image > img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: contain;
}

@media (max-width: 500px) { 
    .panel-container {
        width: 100%;
        min-width: none;
    }
}