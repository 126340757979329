.error-upload-container {
    width: 400px;
    position: fixed;
    height: 80px;
    top: calc(50% - 50px);
    left: calc(50% - 200px);
    z-index: 5;
    background-color: #FFF;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 0 17px -10px rgb(0 0 0 / 50%);
    animation: 0.3s show-error ease-in-out;
}

.error-icon-container {
    height: 80px;
    width: 80px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #fc6161;
    display: grid;
    place-items: center;
}

.error-icon-container > svg {
    color: #FFF;
    font-size: 50px;
}

.error-message-container {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.error-title, .error-message{
    width: 100%;
}
.error-title > h5{
    font-size: 25px;
    color: #050505;
    text-align: left;
}

.error-message > p{
    font-size: 15px;
    color: rgb(131, 131, 131);
    text-align: left;
}

.close-error {
    position: absolute;
    right: -16px;
    cursor: pointer;
    top: -16px;
}

@media screen and (max-width: 500px) {
    .error-upload-container {
        flex-direction: column;
        width: 100%;
        left: 0;
        top: 0;
        height: 100vh;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        box-shadow: none;
    }

    .error-message-container {
        flex-grow: 0;
    }

    .error-title > h5, .error-message > p {
        text-align: center;
    }

    .error-icon-container {
        border-radius: 50%;
    }

    .close-error {
        top: 20px;
        right: 20px;
    }
}

@keyframes show-error {
    0% {
        opacity: 0;
        transform: translateX(200%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
  }