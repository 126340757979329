.modal-container {
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 6;
    display: grid;
    place-items: center;
    background-color: #4b5672e0;
}

.modal {
    width: 60%;
    max-width: 500px;
    min-width: 250px;
    max-height: calc(100vh - 40px);
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0 0 20px -17px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.modal-header {
    position: relative;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(211, 211, 212);
}

.modal-header-close {
    position: absolute;
    right: 10px;
}

.modal-header-close svg{
    color: #050505;
}

.modal-header-title > p{
    font-size: 20px;
    font-weight: 550;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.modal-content-container {
    flex-grow: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.modal-content-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px 0;
}

@media screen and (max-width: 500px)  {
    .modal {
        border-radius: 0;
        width: 100%;
        min-height: auto;
        min-width: auto; 
        max-height: 100vh;
    }
}