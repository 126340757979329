.grid-container {
    width: 100%;
    background-color: whitesmoke;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
}

.grid-name {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.grid-name > p {
    font-size: 15px;
    cursor: default;
}

.grid-menu-btn {
    position: relative;
}

.grid-menu {
    position: absolute;
    z-index: 2;
    right: -20px;
    top: 0;
    outline: 0;
    position: absolute;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px -8px rgb(0 0 0 / 12%);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    cursor: default;
}

.grid-menu > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.grid-menu > ul > li {
    padding: 5px 14px;
    width: 150px;
    flex-grow: 1;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.grid-menu > ul > li:hover {
    background-color: rgba(0, 0, 0, 0.1);
}