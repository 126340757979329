.input-base {
	background-clip: padding-box;
    width: 100%;
    font-size: 15px;
    caret-color: #0067dd;
    margin: 0px;
    margin: 5px 0;
    outline: none;
    padding: 7px 10px;
    transition: all 0.1s ease 0s;
    border: 2px solid #75a3d2;
    background-color: #FFF;
    box-shadow: none;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    color: #050505;
    border-radius: 5px;
}

.input-base::placeholder {
	color: grey;
}

.input-base:focus {
    box-shadow: 0 0 4px #75a3d2;
}