.file-input-label > p, .name-input-label > p {
    font-size: 15px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: left;
}

.file-container {
    margin-top: -8px;
    width: 100%;
    padding: 20px;
    border: 1px solid rgb(211, 211, 212);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-container > svg {
    color: #0067dd;
    margin-right: 5px;
}

.file-button {
    padding: 1em 2em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    width: 100%;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease 0s;
}

.file-button:hover {
    background-color: #2EE59D;
    box-shadow: 0px 7px 13px rgba(46, 229, 157, 0.4);
    color: #fff;
}

.file-input {
    width: 0;
    height: 0;
    margin: 0;
}

.closed {
    display: none;
}