.markers-popup-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.markers-popup-atributes-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
}

.markers-popup-atribute-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 2px;
}
.markers-popup-atribute {
    font-family: 'monserrat';
    font-size: 15px;
    color: #050505;
    text-align: left;
}

.markers-popup-tittle-container {
    width: 100%;
    display: flex;
    justify-content:center;
    padding: 2px;
}


.markers-popup-tittle {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px;
    color: #050505;
    text-align: center;
}

.maplibregl-popup-close-button {
    border-radius: 50% !important;
    font-size: 20px !important;
}

.maplibregl-popup-content {
    border-radius: 10px !important;
}

.button-popup {
    padding: 5px 3px;
}