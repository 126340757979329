.input-label {
    display: flex;
}

.modal-content {
    display: flex;
    flex-direction: column;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    padding: 8px !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    margin-top: 5px;
}