.delete-message-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.delete-message-icon > svg {
    color: #ff313b;
    font-size: 60px;
}

.delete-message {
    padding: 0 10px;
}

.delete-message > p {
    font-size: 20px;
    color: #050505;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.delete-btn {
    background-color: #ff313b !important;
}

.delete-btn:hover {
    background-color: #f01d28 !important;
}