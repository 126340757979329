.sidebar-container {
    top: 0;
    left: 0;
    width: 360px;
    bottom: 56px;
    margin: 20px;
    display: flex;
    z-index: 4;
    position: absolute;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    transform: translateX(-130%);
    transition: transform .5s ease;
}

.sidebar-search-container {
    min-height: 50px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    z-index: 1;
}

.input-search-container {
    display: flex;
    position: relative;
    align-items: center;
    padding: 0px 10px;
    min-height: 64px;
}

.sidebar-title-container {
    padding: 8px !important;
    flex: 1;
    display: flex;
    align-items: center;
}

.sidebar-input-search {
    margin: 0px 10px !important;
}

.sidebar-content-container {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    display: flex;
}

.sidebar-content-overflow {
    overflow-y: auto;
    flex-grow: 1;
}

.sidebar-content {
    padding: 15px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.sidebar-open {
    transform: translateX(0);
}

@media screen and (max-width: 500px)  {
    .sidebar-container {
        width: 100%;
        margin: 0;
        bottom: 0;
    }
}
